.ant-alert {
    font-size: var(--main-font-size);
    line-height: $line-height-base;
    color: var(--primary-text-color);
    position: relative;
    padding: $alert-padding-y $alert-padding-x $alert-padding-y $alert-padding-w-icon;
    border-radius: var(--border-radius-2x);
}

.ant-alert-info {
    background: color-mix(in srgb, var(--info-color) 10%, transparent);
    border: var(--border-size-x) solid color-mix(in srgb, var(--info-color) 15%, transparent);

    .ant-alert-icon {
        color: var(--info-color);
    }
}

.ant-alert-warning {
    background: color-mix(in srgb, var(--warning-color) 10%, transparent);
    border: var(--border-size-x) solid color-mix(in srgb, var(--warning-color) 15%, transparent);

    .ant-alert-icon {
        color: var(--warning-color);
    }
}

.ant-alert-success {
    background: color-mix(in srgb, var(--success-color) 10%, transparent);
    border: var(--border-size-x) solid color-mix(in srgb, var(--success-color) 15%, transparent);

    .ant-alert-icon {
        color: var(--success-color);
    }
}

.ant-alert-error {
    background: color-mix(in srgb, var(--danger-color) 10%, transparent);
    border: var(--border-size-x) solid color-mix(in srgb, var(--danger-color) 15%, transparent);

    .ant-alert-icon {
        color: var(--danger-color);
    }
}

.ant-alert-description {
    font-size: var(--main-font-size);
}

.ant-alert-message {
    color: var(--primary-text-color);
}

.ant-alert-with-description {
    padding: var(--padding-2x) var(--padding-4x);
    position: relative;
    border-radius: var(--border-radius-2x);
    color: var(--text-color);
    line-height: $line-height-base;

    .ant-alert-message {
        font-size: var(--title-font-size);
        color: var(--primary-text-color);
        margin-bottom: var(--margin-x);
    }
}

.ant-alert-close-icon {
    .anticon-close {
        color: var(--text-color);

        &:hover {
            color: var(--primary-text-color);
        }
    }
}

.ant-alert-close-text {
    color: var(--primary-text-color);
}
