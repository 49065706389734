.ant-steps {
    font-family: $font-family-base;
    line-height: $line-height-base;
}

.ant-steps-item-icon {
    border: $step-line-width solid $border-color;
    width: $step-icon-size;
    height: $step-icon-size;
    line-height: $step-icon-size;
    text-align: center;
    border-radius: $step-icon-size;
    font-size: 1.143rem;
    font-family: $font-family-base;

    > .ant-steps-icon {
        top: 0px;
    }
}

.ant-steps-item-title {
    color: var(--text-color);
}

.ant-steps-item-description,
.ant-steps-item-subtitle {
    color: $gray-light;
}

.ant-steps-item-wait {
    .ant-steps-item-icon {
        border-color: var(--component-header-background-color);
        background-color: var(--component-header-background-color);

        > .ant-steps-icon {
            color: var(--disabled-text-color);
        }
    }
    > .ant-steps-item-container {
        > .ant-steps-item-content {
            > .ant-steps-item-description {
                color: var(--disabled-text-color);
            }

            > .ant-steps-item-title {
                color: var(--disabled-text-color);
            }
        }
    }
}

.ant-steps-item-process {
    > .ant-steps-item-container {
        > .ant-steps-item-icon {
            background: var(--primary-color);
            border-color: var(--primary-color);

            > .ant-steps-icon {
                .ant-steps-icon-dot {
                    background: var(--primary-color);
                }
            }
            .ant-steps-icon {
                color: white;
            }
        }
        > .ant-steps-item-content {
            > .ant-steps-item-title {
                color: var(--primary-text-color);

                &:after {
                    background-color: $border-color;
                }
            }

            > .ant-steps-item-tail {
                &:after {
                    background-color: $border-color;
                }
            }

            > .ant-steps-item-description {
                color: var(--text-color);
            }
        }
    }

    > .ant-steps-item-container {
        > .ant-steps-item-content > .ant-steps-item-title:after,
        > .ant-steps-item-tail:after {
            background-color: var(--secondary-border-color);
        }
    }
}

.ant-steps-item-finish {
    .ant-steps-item-icon {
        border-color: var(--primary-color);
        background-color: transparent;

        .ant-steps-icon {
            color: var(--primary-color);

            .ant-steps-icon-dot {
                background: $step-theme-colors;
            }
        }
    }

    > .ant-steps-item-container {
        > .ant-steps-item-content {
            .ant-steps-item-title {
                color: var(--disabled-text-color);

                &:after {
                    background-color: var(--primary-color);
                }
            }
            > .ant-steps-item-description {
                color: var(--disabled-text-color);
            }
        }

        > .ant-steps-item-tail {
            &:after {
                background-color: var(--primary-color);
            }
        }
    }
}

.ant-steps-item-error {
    .ant-steps-item-icon {
        border-color: var(--danger-color);
        background-color: transparent;

        > .ant-steps-icon {
            color: var(--danger-color);
        }
    }

    > .ant-steps-item-container {
        > .ant-steps-item-content {
            > .ant-steps-item-description,
            > .ant-steps-item-title {
                color: $brand-danger;
            }
        }
    }
}

.ant-steps-item {
    &.ant-steps-next-error {
        .ant-steps-item-title {
            &:after {
                background: $brand-danger;
            }
        }
    }
}

.ant-steps-item-tail,
.ant-steps-item-title {
    &::after {
        height: $step-line-width;
    }
}

.ant-steps-small {
    .ant-steps-item-icon {
        width: $step-icon-size-sm;
        height: $step-icon-size-sm;
        line-height: $step-icon-size-sm;
        text-align: center;
        border-radius: $step-icon-size-sm;
        font-size: 0.857rem;
    }
}

.ant-steps-vertical {
    > .ant-steps-item {
        > .ant-steps-item-tail {
            &:after {
                width: $step-line-width;
            }
        }
    }
}

.ant-steps-dot {
    .ant-steps-item-tail {
        top: 3px;
    }
}

.ant-steps-navigation {
    .ant-steps-item {
        &:before {
            background-color: $step-theme-colors;
        }
    }
}
