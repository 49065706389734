section,
.section {
  border: var(--border-size-x) solid var(--primary-border-color);
  padding: 20px;
  background-color: var(--section-background-color);
  color: var(--text-color);
  border-radius: 0.25rem;
  width: 100%;
  margin-bottom: var(--margin-3x);
}

rappider-json-array {
  width: 100%;
}

.page-toolbar {
  display: block;
  margin-top: var(--margin-3x);
  margin-bottom: var(--margin-3x);
}

.full-block {
  display: block;
  width: 100%;
  height: 100%;
}

.anticon {
  vertical-align: 0.125rem;
}

.ant-card-bordered {
  border-color: #ddd;
}

.ant-card {
  border-radius: 0;
}

//scroll styles

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #a4a4a5;
  border-radius: 16px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #8a8a8a;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

/* disable text selection */
.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

}

.asterisk::before {
  display: inline-block;
  margin-right: var(--margin-x);
  color: var(--danger-color);
  font-size: var(--h6-font-size);
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

rappider-monaco-code-editor {
  display: contents !important;
}

rappider-monaco-editor {
  display: contents !important;
}

/* Used to style the highlighted editable lines when we restrict the code editor */
.monaco-editable-line {
  // set a very lig
  background-color: rgba(255, 255, 255, 0.1)
}
