.ant-avatar {
    font-family: $font-family-sans-serif;
    font-size: var(--subtext-font-size);
    line-height: $line-height-base;
    color: var(--text-color);
    text-align: center;
    background: var(--component-header-background-color);
    white-space: nowrap;
    position: relative;
    overflow: hidden;
    vertical-align: middle;
    width: $avatar-size;
    height: $avatar-size;
    line-height: $avatar-size;
    border-radius: $border-radius-circle;
    display: flex;
    align-items: center;
    justify-content: center;

    &.ant-avatar-icon {
        font-size: $avatar-icon;
    }
}

.ant-avatar-lg {
    width: $avatar-size-lg;
    height: $avatar-size-lg;
    line-height: $avatar-size-lg;

    > * {
        line-height: $avatar-size-lg;
    }

    &.ant-avatar-icon {
        font-size: $avatar-icon-lg;
    }
}

.ant-avatar-sm {
    width: $avatar-size-sm;
    height: $avatar-size-sm;
    line-height: $avatar-size-sm;

    > * {
        line-height: $avatar-size-sm;
    }

    &.ant-avatar-icon {
        font-size: $avatar-icon-sm;
    }
}

.ant-avatar-square {
    border-radius: $border-radius;
}

@each $tone in $tone-loop {
    .ant-avatar-#{nth($tone, 1)} {
        color: #{nth($tone, 2)};
        background: #{nth($tone, 3)};
    }
}

.avatar-string {
    .ant-avatar {
        margin-left: -5px;
        border: 2px solid $white;
    }
}
