.ant-modal-wrap {
    position: fixed;
    overflow: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zindex-modal;
}

.ant-modal {
    font-family: $font-family-sans-serif;
    font-size: var(--main-font-size);
    line-height: $line-height-base;
    color: var(--text-color);
    width: auto;
    margin: 0 auto;
    top: 6.25rem;
    padding-bottom: 1.5rem;
}

.ant-modal-content {
    background-color: var(--component-inner-background-color);
    border-radius: var(--border-radius-2x);
    box-shadow: var(--box-shadow);
}

.ant-modal-close {
    z-index: 10;
    font-weight: var(--font-weight-bold);
    line-height: 1;
    color: var(--text-color) !important;
}

.ant-modal-header {
    padding: var(--padding-3x) var(--padding-5x);
    border-radius: var(--border-radius-2x) var(--border-radius-2x) 0 0;
    background: var(--component-header-background-color);
    color: var(--primary-text-color);
    border-bottom: var(--border-size-x) solid var(--primary-border-color);
}

.ant-modal-title {
    color: var(--primary-text-color);
    font-weight: var(--font-weight-medium);
    font-size: var(--title-font-size);
}

.ant-modal-body {
    padding: $modal-body-padding;
    font-size: var(--main-font-size);
    line-height: $line-height-base;
    max-height: var(--modal-max-height);
    overflow: scroll;
}

.ant-modal-footer {
    border-top: var(--border-size-x) solid var(--primary-border-color);
    padding: var(--padding-2x) var(--padding-4x);
    text-align: right;
    border-radius: 0 0 var(--border-radius-2x) var(--border-radius-2x);
}

.ant-confirm-body {
    > .anticon {
        font-size: 1.571rem;
        margin-right: 1rem;
        float: left;
    }

    .ant-confirm-title {
        color: $gray-dark;
        font-weight: var(--font-weight-medium);
        font-size: var(--title-font-size);
        line-height: $line-height-base;
    }

    .ant-confirm-content {
        margin-left: 38px;
        font-size: var(--main-font-size);
        color: var(--text-color);
        margin-top: 8px;
    }
}

.ant-confirm-success {
    .ant-confirm-body {
        > .anticon {
            color: var(--success-color);
        }
    }
}

.ant-confirm-error {
    .ant-confirm-body {
        > .anticon {
            color: var(--danger-color);
        }
    }
}

.ant-confirm-confirm,
.ant-confirm-warning {
    .ant-confirm-body > {
        .anticon {
            color: var(--warning-color);
        }
    }
}

.vertical-center-modal {
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-modal {
        top: 0px;
    }
}

.ant-modal-confirm-body {
    .ant-modal-confirm-title {
        color: var(--primary-text-color);
    }
    .ant-modal-confirm-content {
        color: var(--text-color);
        font-size: var(--main-font-size);
    }
}
