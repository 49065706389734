.ant-switch {
    font-family: $font-family-sans-serif;
    font-size: $font-size-base;
    color: var(--text-color);
    line-height: $line-height-base;
    height: $switch-height;
    min-width: $switch-min-width;
    background-color: var(--component-header-background-color);

    &:after {
        width: $switch-size;
        height: $switch-size;
        border-radius: $switch-size;
        background-color: $white;
    }

    &:before {
        width: $switch-size;
        height: $switch-size;
        font-size: $switch-loading-size;
    }
}

.ant-switch-checked {
    background-color: var(--primary-color);

    &:after,
    &:before {
        left: 100%;
    }

    &:before {
        margin-left: -$switch-size - 1px;
    }
}

.ant-switch-small {
    height: $switch-height-sm;
    min-width: $switch-min-width-sm;
    line-height: $line-height-base;

    &:after {
        width: $switch-size-sm;
        height: $switch-size-sm;
        border-radius: $switch-size-sm;
    }

    &:before {
        width: $switch-size-sm;
        height: $switch-size-sm;
        font-size: $switch-loading-size-sm;
    }

    &.ant-switch-checked {
        &:after,
        &:before {
            left: 100%;
            margin-right: -$switch-size-sm - 1px;
        }
    }

    .ant-switch-handle {
        top: 1px;
    }
}

.ant-switch-handle {
    top: 3px;
    left: 3px;
}

.ant-switch-inner {
    color: var(--text-color);
    font-size: var(--subtext-font-size);
    margin-left: $switch-size + 6px;
    margin-right: var(--margin-2x);
    line-height: 0;
}

.ant-switch-checked {
    .ant-switch-handle {
        left: calc(100% - 18px - 3px);
    }

    .ant-switch-inner {
        color: white;
    }
}

.ant-switch-loading-icon {
    top: 1px;
}
