.ant-slider {
    font-family: $font-family-sans-serif;
    font-size: $font-size-base;
    color: var(--text-color);
    height: $slider-height;
    margin: $slider-gutter;

    &:hover {
        .ant-slider-track {
            background-color: var(--primary-hover-color);
        }

        .ant-slider-rail {
            background-color: $slider-rail-bg;
        }

        .ant-slider-handle {
            &:not(.ant-tooltip-open) {
                border-color: var(--primary-color);
            }
        }

        .ant-slider-dot {
            border-color: var(--primary-border-color);
        }

        .ant-slider-dot-active {
            border-color: var(--primary-color);
        }
    }
}

.ant-slider-rail {
    width: 100%;
    height: $slider-track-height;
    border-radius: $slider-rail-radius;
    background-color: rgba($slider-rail-bg, $slider-hover-opacity);
}

.ant-slider-track {
    height: $slider-rail-height;
    border-radius: $slider-rail-radius;
    background-color: var(--primary-color);
}

.ant-slider-handle {
    position: absolute;
    margin-top: $slider-ticker-top-adjust;
    width: $slider-ticker-size;
    height: $slider-ticker-size;
    border: var(--border-size-2x) solid var(--primary-color);
    background-color: var(--default-color);
}

.ant-slider-handle:focus {
    border-color: var(--primary-color);
    box-shadow: 0 0 0 2px var(--focus-color);
}

.ant-slider-dot {
    position: absolute;
    width: $slider-dot-size;
    height: $slider-dot-size;
    border: var(--border-size-2x) solid var(--primary-border-color);
    background-color: var(--input-background-color);
}

.ant-slider-dot-active {
    border: var(--border-size-2x) solid var(--primary-color);
}

.ant-slider-mark {
    top: 1.25rem;
}

.ant-slider-mark-text {
    color: var(--text-color);
}

.ant-slider-mark-text-active {
    color: var(--text-color);
}

.ant-slider-vertical {
    height: 100%;

    .ant-slider-handle {
        margin-left: $slider-ticker-left-adjust;
        margin-bottom: $slider-ticker-top-adjust;
    }

    .ant-slider-mark-text {
        left: 0.625rem;
    }
}
