.ant-popover {
    font-family: $font-family-sans-serif;
    font-size: $font-size-base;
    line-height: $line-height-base;
    color: $body-color;
    z-index: $zindex-popover;
}

.ant-popover-placement-top,
.ant-popover-placement-topLeft,
.ant-popover-placement-topRight {
    padding-bottom: $popover-placement-gutter;
}

.ant-popover-placement-left,
.ant-popover-placement-leftBottom,
.ant-popover-placement-leftTop {
    padding-right: $popover-placement-gutter;
}

.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
    padding-top: $popover-placement-gutter;
}

.ant-popover-placement-right,
.ant-popover-placement-rightBottom,
.ant-popover-placement-rightTop {
    padding-left: $popover-placement-gutter;
}

.ant-popover-inner {
    background-color: var(--component-header-background-color);
    border-radius: $border-radius;
    box-shadow: 0 2px 8px rgba($gray-dark, 0.15);
}

.ant-popover-title {
    min-width: 180px;
    padding: 0.3125rem 0.9375rem;
    min-height: auto;
    border-bottom: var(--border-size-x) solid var(--primary-border-color);
    color: var(--primary-text-color);
    font-weight: 500;
}

.ant-popover-message-title {
    color: var(--primary-text-color);
}

.ant-popover-inner-content {
    padding: 0.75rem 1rem;
    color: var(--text-color);
}

.ant-popover-arrow-content {
    background-color: var(--component-header-background-color);
}
