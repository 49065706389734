.ant-picker {
    color: var(--text-color);
    font-size: var(--main-font-size);
    line-height: $input-line-height;
    padding: 4px 11px 4px;
    background: var(--input-background-color);
    border: var(--border-size-x) solid var(--primary-border-color);
    border-radius: var(--border-radius-2x);
    height: $date-picker-height;

    &:hover,
    .ant-picker-focused {
        border-color: var(--primary-color);
    }

    .ant-picker-input {
        > input {
            color: var(--text-color);

            @include placeholder {
                color: var(--placeholder-color);
            }
        }
    }

    &.ant-picker-disabled {
        background: var(--disabled-color);
        border-color: var(--secondary-border-color);
    }
}

.ant-picker-large {
    padding: $input-padding-y-lg $input-padding-x-lg $input-padding-y-lg;
    height: $date-picker-height-lg;

    .ant-picker-input {
        > input {
            font-size: $input-font-size-lg;
        }
    }
}

.ant-picker-small {
    padding: 0 $input-padding-x-sm 0;

    height: $date-picker-height-sm;

    .ant-picker-input {
        > input {
            font-size: $input-font-size-sm;
        }
    }
}

.ant-picker-focused {
    border-color: var(--primary-border-color);
    box-shadow: 0 0 0 2px var(--focus-color);

    .ant-picker-separator {
        color: var(--text-color);
    }
}

.ant-picker-input {
    > input:focus,
    > .input-focused {
        border-color: var(--primary-border-color);
    }
}

.ant-picker-range {
    .ant-picker-active-bar {
        background: var(--primary-color);
    }
}

.ant-picker-content {
    th {
        color: var(--primary-text-color);
        font-weight: $font-weight-semibold;
    }
}

.ant-picker-cell {
    color: var(--disabled-text-color);

    &:hover {
        &:not(.ant-picker-cell-in-view) {
            .ant-picker-cell-inner {
                background: var(--component-header-background-color);
            }
        }

        &:not(.ant-picker-cell-selected)
            :not(.ant-picker-cell-range-start)
            :not(.ant-picker-cell-range-end)
            :not(.ant-picker-cell-range-hover-start)
            :not(.ant-picker-cell-range-hover-end) {
            .ant-picker-cell-inner {
                background: var(--component-header-background-color);
            }
        }
    }

    &:before {
        height: $picker-calendar-mini-cell-size;
    }

    .ant-picker-cell-inner {
        min-width: $picker-calendar-mini-cell-size;
        height: $picker-calendar-mini-cell-size;
        line-height: $picker-calendar-mini-cell-size;
        border-radius: var(--border-radius-2x);
    }
}

.ant-picker-cell-in-view {
    color: var(--text-color);

    &.ant-picker-cell-today {
        .ant-picker-cell-inner {
            color: var(--primary-color);

            &:before {
                border: var(--border-size-x) solid var(--primary-color);
                border-radius: var(--border-radius-2x);
            }
        }
    }

    &.ant-picker-cell-in-range {
        &:before {
            background: var(--default-color);
        }
    }

    &.ant-picker-cell-range-start {
        &:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) {
            .ant-picker-cell-inner {
                border-radius: var(--border-radius-2x);
            }
        }

        &:not(.ant-picker-cell-range-start-single)::before {
            background: var(--default-color);
        }
    }

    &.ant-picker-cell-range-end {
        &:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) {
            .ant-picker-cell-inner {
                border-radius: var(--border-radius-2x);
            }
        }

        &:not(.ant-picker-cell-range-end-single)::before {
            background: var(--default-color);
        }
    }

    &.ant-picker-cell-selected,
    &.ant-picker-cell-range-start,
    &.ant-picker-cell-range-end {
        .ant-picker-cell-inner {
            color: $white;
            background: var(--primary-color);
        }

        &:hover {
            .ant-picker-cell-inner {
                color: $white;
                background: var(--primary-color);
            }
        }
    }
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(
        .ant-picker-cell-range-start
    ):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(
        .ant-picker-cell-range-start
    ):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
    height: $picker-calendar-mini-cell-size;
    border-top: var(--border-size-x) dashed var(--primary-color);
    border-bottom: var(--border-size-x) dashed var(--primary-color);
}

.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(
        .ant-picker-cell-range-start-single
    ).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(
        .ant-picker-cell-range-end-single
    ).ant-picker-cell-range-hover-end::before,
.ant-picker-panel
    > :not(.ant-picker-date-panel)
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.ant-picker-panel
    > :not(.ant-picker-date-panel)
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
    background: var(--default-color);
}

.ant-picker-date-panel {
    .ant-picker-cell-in-view {
        &.ant-picker-cell-in-range {
            &.ant-picker-cell-range-hover-end,
            &.ant-picker-cell-range-hover-start {
                .ant-picker-cell-inner {
                    color: var(--primary-color);
                    font-weight: $font-weight-bold;
                    font-size: $font-size-base;
                }
            }
        }
    }

    .ant-picker-cell-in-view.ant-picker-cell-in-range {
        &.ant-picker-cell-range-hover-start,
        &.ant-picker-cell-range-hover-end {
            .ant-picker-cell-inner {
                &::after {
                    background: transparent;
                }
            }
        }
    }
}

.ant-picker-week-panel-row-selected {
    td {
        background: var(--default-color);
    }

    &:hover {
        td {
            background: var(--default-color);
        }
    }
}

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(
        .ant-picker-cell-range-hover-edge-start-near-range
    )::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
    border-left: var(--border-size-x) dashed var(--primary-color);
}

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(
        .ant-picker-cell-range-hover-edge-end-near-range
    )::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
    border-right: var(--border-size-x) dashed var(--primary-color);
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after {
    border-top-left-radius: var(--border-radius-2x);
    border-bottom-left-radius: var(--border-radius-2x);
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
    border-top-right-radius: var(--border-radius-2x);
    border-bottom-right-radius: var(--border-radius-2x);
}

.ant-picker-suffix {
    color: var(--placeholder-color);
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
    color: var(--primary-color);
}

.ant-picker-separator {
    color: var(--text-color);
}

.ant-picker-clear {
    background-color: var(--input-background-color);
    color: var(--text-color);
}

.ant-picker-super-prev-icon::before,
.ant-picker-super-prev-icon::after,
.ant-picker-prev-icon::before,
.ant-picker-prev-icon::after,
.ant-picker-super-next-icon::before,
.ant-picker-super-next-icon::after,
.ant-picker-next-icon::before,
.ant-picker-next-icon::after {
    color: var(--text-color);
}
