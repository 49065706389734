.ant-comment-content-author-name {
    color: var(--primary-text-color);
    font-weight: var(--font-weight-semibold);
    font-size: var(--title-font-size);
}

.ant-comment-content-detail p {
    color: var(--text-color);
    font-size: var(--main-font-size);
}

.ant-comment-actions {
    > li {
        color: var(--text-color);

        > span {
            color: var(--text-color);
            font-size: var(--main-font-size);

            i {
                margin-right: var(--margin-x);
            }
        }
    }
}

.ant-comment-content-author-time {
    color: var(--text-color);
}

.ant-comment-avatar {
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
}
