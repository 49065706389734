.ant-tabs {
    font-family: $font-family-base;
    font-size: var(--main-font-size);
    line-height: $line-height-base;
    color: var(--text-color);

    &:not(.ant-tabs-vertical) {
        > .ant-tabs-content {
            > .ant-tabs-tabpane {
                flex-shrink: 0;
                width: 100%;
                padding: 0px;
                opacity: 1;
            }
        }
    }

    &.ant-tabs-card {
        > .ant-tabs-nav {
            .ant-tabs-nav-container {
                height: $tabs-card-height;
            }

            .ant-tabs-tab {
                margin: 0 -1px 0 0;
                border: var(--border-size-x) solid var(--secondary-border-color);
                border-radius: 0;
                background: var(--component-header-background-color);
                padding: 0 var(--padding-4x);
                line-height: calc(#{$tabs-card-height} - 2px);
            }

            .ant-tabs-tab-active {
                background: var(--section-background-color) !important;
                border-color: var(--secondary-border-color);
                color: var(--text-color);
                padding-bottom: 1px;
            }

            .anticon-close {
                color: var(--text-color);
                font-size: 0.857rem;
                margin-left: 3px;
                margin-right: -5px;
                width: 16px;
                height: 16px;
                height: 14px;

                &:hover {
                    color: $gray-dark;
                }
            }
        }
    }
}

.ant-tabs-nav {
    border-bottom: var(--border-size-x) solid var(--primary-border-color);
    margin-bottom: $tabs-gap;
    outline: 0;
    text-decoration: none;

    .ant-tabs-tab-active {
        a {
            color: var(--primary-color);

            &:hover {
                color: var(--primary-color);
            }
        }
    }

    a {
        color: $tabs-title-color;

        &:hover {
            text-decoration: none;
            color: $tabs-title-hover-color;
        }
    }
}

.ant-tabs-nav-container {
    font-size: var(--main-font-size);
    line-height: $line-height-base;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    margin-bottom: -1px;
}

.ant-tabs-tab-next,
.ant-tabs-tab-prev {
    z-index: 2;
    cursor: pointer;
    border: 0;
    background-color: transparent;
    position: absolute;
    text-align: center;
    color: var(--text-color);
    opacity: 0;

    &:hover {
        color: $tabs-arrow-color-hover;
    }

    &.ant-tabs-tab-arrow-show {
        width: $tabs-arrow-width;
    }
}

.ant-tabs-tab-btn-disabled {
    color: rgba($tabs-arrow-color, 0.25);

    &:hover {
        color: rgba($tabs-arrow-color, 0.25);
    }
}

.ant-tabs-tab-btn-disabled {
    cursor: not-allowed;
}

.ant-tabs-nav {
    .ant-tabs-tab {
        display: inline-block;
        height: 100%;
        margin-right: var(--padding-4x);
        padding: var(--padding-2x) var(--padding-4x);
        box-sizing: border-box;
        position: relative;
        color: var(--text-color);

        &:hover {
            color: var(--text-color);
        }
    }

    .ant-tabs-tab-active {
        color: var(--text-color);
        font-weight: 500;
    }

    .ant-tabs-tab-disabled {
        color: var(--disabled-text-color);
    }
}

.ant-tabs-ink-bar {
    height: 2px;
    background: var(--primary-color);
}

.ant-tabs-large {
    .ant-tabs-tab {
        font-size: $tabs-toggler-lg;
    }
}

.ant-tabs-small {
    .ant-tabs-tab {
        font-size: $tabs-toggler-sm;
    }
}

.ant-tabs-tab {
    &.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
            color: var(--primary-color);
            font-weight: $font-weight-semibold;
        }
    }
}

.card-container {
    .ant-tabs-bar {
        margin: 0;
        border-bottom: none;

        .ant-tabs-nav-container {
            .ant-tabs-nav-wrap {
                .ant-tabs-nav-scroll {
                    .ant-tabs-tab {
                        font-size: 1rem;
                        font-weight: bold;
                    }
                }
            }
        }
    }

    .ant-tabs-nav {
        border-bottom: 0px solid var(--secondary-border-color);
        outline: 0;
        text-decoration: none;

        &::before {
            border-bottom: var(--border-size-x) solid var(--secondary-border-color);
        }
    }

    .ant-tabs-content {
        margin-left: 0%;

        .ant-tabs-tabpane {
            border: var(--border-size-x) solid var(--secondary-border-color);
            border-radius: 0.25rem;
            padding: 20px !important;
            // margin-top: -18px; NEGATIVE MARGINS CAUSE ISSUES
        }
    }
}

.ant-tabs.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active {
    border-bottom: 0px solid var(--secondary-border-color);
}

.ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: var(--border-size-x) solid var(--secondary-border-color);
}
