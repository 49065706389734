// Form input states
@mixin form-focus-state($color) {
  border-color: $color;
  box-shadow: 0 0 0 2px rgba($color, 0.2);
}

@mixin form-input-state($color) {
  border-color: $color;

  &:hover,
  &:focus {
    border-color: $color;
    background-color: $white;
  }
}

.ant-form {
  color: var(--text-color);
}

.ant-form-item-label {
  text-align: left !important;
  label {
    color: var(--text-color);
    font-weight: var(--font-weight-semibold);
    height: auto;
    padding: $input-padding-x 0px;
    font-size: var(--main-font-size);
    justify-content: flex-start !important;
  }
}

.ant-form-item-control-input {
  min-height: $input-height;
  label {
    padding: 5px 0px;
  }
}

.ant-form-small {
  .ant-form-item-label {
    > label {
      height: $input-height-sm;
    }
  }

  .ant-form-item-control-input {
    min-height: $input-height-sm;
  }

}

.ant-form-large {
  .ant-form-item-label {
    > label {
      height: $input-height-lg;
    }
  }

  .ant-form-item-control-input {
    min-height: $input-height-lg;
  }
}

.ant-form-item-has-error {
  .ant-input,
  .ant-input-affix-wrapper {
    background-color: transparent !important;
    @include form-input-state($input-error-color);

    &:focus {
      @include form-focus-state($input-error-color);
    }
  }

  .ant-input-focused,
  .ant-input-affix-wrapper-focused,
  .ant-input-number-focused,
  .ant-picker-focused {
    @include form-focus-state($input-error-color);
  }

  .ant-input-prefix,
  .ant-form-item-explain,
  .ant-form-item-split,
  &.ant-form-item-has-feedback .ant-form-item-children-icon {
    color: $input-error-color;
  }

  .ant-input-number,
  .ant-picker,
  .ant-select .ant-select-selector {
    @include form-input-state($input-error-color);
  }
}

.ant-form-item-has-warning {
  .ant-input,
  .ant-input-affix-wrapper {
    @include form-input-state($input-warning-color);

    &:focus {
      @include form-focus-state($input-warning-color);
    }
  }

  .ant-input-focused,
  .ant-input-affix-wrapper-focused {
    @include form-focus-state($input-warning-color);
  }

  .ant-input-prefix,
  &.ant-form-item-has-feedback .ant-form-item-children-icon {
    color: $input-warning-color;
  }
}

.ant-form-item-has-success {
  &.ant-form-item-has-feedback .ant-form-item-children-icon,
  .ant-input-prefix {
    color: $input-success-color;
  }
}

.ant-form-item-is-validating {
  &.ant-form-item-has-feedback .ant-form-item-children-icon {
    color: $loading-color;
  }
}

.ant-form-explain,
.ant-form-extra {
  color: $gray-light;
  line-height: $line-height-base;
  margin-top: 5px;
}

.ant-form-item {
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: var(--text-color);
  margin: 0px;
  margin-bottom: 24px;
}

.ant-form-item-extra {
  color: var(--text-color);
}

.ant-input-textarea-show-count:after {
  color: var(--text-color);
  font-size: 0.929rem;
  float: none;
  display: flex;
  justify-content: flex-end;
}
