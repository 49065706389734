body {
  font-family: $font-family-base;
  background-color: var(--body-background-color);
  color: $body-color;

  margin: 0;
  font-size: 1rem;
  font-weight: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased !important;
  min-height: calc(100vh);
}

a {
  color: var(--link-color) !important;
  text-decoration: none !important;

  &:active,
  &:hover {
    color: var(--link-hover-color);
    text-decoration: underline !important;
  }

  &:focus {
    text-decoration: none;
  }

  &.text-gray,
  &.text-muted {
    &:hover {
      color: $gray-dark !important;
    }
  }

  &.text-dark {
    &:hover {
      color: $link-hover-color !important;
    }
  }

  &.file {
    &:hover {
      background-color: #f9fbfd;
    }
  }
}

hr {
  border: 0px;
  border-top: 1px solid $border-color;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

code {
  margin: 0 1px;
  background: $gray-lightest;
  padding: 0.2em 0.4em;
  border-radius: 3px;
  font-size: 0.9em;
  border: 1px solid $border-color;
  color: $brand-danger;
}
