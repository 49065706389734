.ant-select {
    color: var(--text-color);
    font-size: var(--main-font-size);
    line-height: $line-height-base;

    &:not(.ant-select-disabled) {
        &:hover {
            .ant-select-selector {
                border-color: var(--primary-color);
                border-right-width: 1px !important;
            }
        }
    }
}

.ant-select-selection-placeholder {
    color: var(--placeholder-color);
}

.ant-select-single {
    &:not(.ant-select-customize-input) {
        .ant-select-selector {
            background-color: var(--input-background-color);
            border: var(--border-size-x) solid var(--secondary-border-color);
            border-radius: var(--border-radius-2x);
            height: $select-height + 0.125rem;
            padding: 0 11px;

            .ant-select-selection-search-input {
                height: 100%;
            }
        }
    }

    &:not(.ant-select-disabled) {
        &:hover {
            .ant-select-selector {
                border-color: var(--primary-color);
            }
        }
    }

    .ant-select-selector {
        .ant-select-selection-item,
        .ant-select-selection-placeholder {
            line-height: $select-height;
        }
    }

    &.ant-select-sm {
        &:not(.ant-select-customize-input) {
            .ant-select-selector {
                height: $select-height-sm + 0.125rem;
            }

            &:not(.ant-select-customize-input) {
                .ant-select-selection-search-input {
                    height: $select-height-sm;
                }
            }

            .ant-select-selector {
                .ant-select-selection-item,
                .ant-select-selection-placeholder {
                    line-height: $select-height-sm;
                }
            }
        }
    }

    &.ant-select-lg {
        &:not(.ant-select-customize-input) {
            .ant-select-selector {
                height: $select-height-lg + 0.125rem;
            }

            &:not(.ant-select-customize-input) {
                .ant-select-selection-search-input {
                    height: $select-height-lg;
                }
            }

            .ant-select-selector {
                .ant-select-selection-item,
                .ant-select-selection-placeholder {
                    line-height: $select-height-lg;
                }
            }
        }
    }
}

.ant-select-multiple {
    .ant-select-selector {
        background-color: var(--input-background-color);
        border: var(--border-size-x) solid var(--secondary-border-color);
        border-radius: var(--border-radius-2x);
        padding: 1px $select-tag-margin-y;
        height: $select-height + 0.125rem;
    }

    .ant-select-selection-item {
        align-items: center;
        line-height: $select-tag-height - 0.125rem;
        margin-right: $select-tag-margin-x;
        padding: 0 8px 0 8px;
        background: var(--input-background-color);
        border: var(--border-size-x) solid var(--secondary-border-color);
        border-radius: var(--border-radius-2x);
    }

    .ant-select-selection-item-remove {
        color: var(--text-color);
        display: flex;

        &:hover {
            color: var(--placeholder-color);
        }
    }

    &.ant-select-sm {
        .ant-select-selector {
            height: $select-height-sm + 0.125rem;
        }

        .ant-select-selection-item {
            height: $select-tag-height-sm;
            line-height: $select-tag-height-sm - 0.125rem;
        }
    }

    &.ant-select-lg {
        .ant-select-selector {
            height: $select-height-lg + 0.125rem;
        }

        .ant-select-selection-item {
            height: $select-tag-height-lg;
            line-height: $select-tag-height-lg - 0.125rem;
        }
    }
}

.ant-select-focused {
    &.ant-select-single {
        &:not(.ant-select-customize-input) {
            .ant-select-selector {
                border-color: var(--primary-color) !important;
                box-shadow: 0 0 0 2px var(--focus-color) !important;
            }
        }
    }

    &.ant-select-multiple {
        .ant-select-selector {
            border-color: var(--primary-color);
            box-shadow: 0 0 0 2px var(--focus-color) !important;
        }
    }
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: var(--primary-color);
}

.ant-select-item {
    color: var(--text-color);
    font-weight: var(--font-weight-normal);
    font-size: var(--main-font-size);
    line-height: $select-item-line-height;
}

.ant-select-item-option-disabled {
    color: var(--select-item-disabled-color, var(--disabled-text-color, unset));
}

.ant-select-item-option-selected {
    &:not(.ant-select-item-option-disabled) {
        color: var(--primary-color);
        font-weight: var(--font-weight-semibold);
        background-color: transparent;

        .ant-select-item-option-state {
            color: var(--primary-color);
        }
    }
}

.ant-select-item-option-active {
    &:not(.ant-select-item-option-disabled) {
        background-color: var(--focus-color);
        color: var(--primary-color);
    }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: var(--input-background-color);
    border: var(--border-size-x) solid var(--secondary-border-color);
    border-radius: var(--border-radius-2x);
    // ng-zorro default select height
    min-height: 35px;
    align-items: center;
}

.ant-select-dropdown {
    background-color: var(--component-inner-background-color);
}

.ant-select-clear {
    background-color: var(--input-background-color);
    color: var(--placeholder-color);

    &:hover {
        color: var(--disabled-text-color);
    }
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: var(--disabled-text-color);
}

.ant-menu-item-disabled,
.ant-menu-submenu-disabled {
    color: var(--disabled-text-color) !important;
}

.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: var(--disabled-color);
}

.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
    color: var(--disabled-text-color);
    border: none;
}

.ant-select-multiple .ant-select-selection-search {
    margin-inline-start: 0;
}
