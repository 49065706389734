.ant-rate {
    font-family: $font-family-sans-serif;
    font-size: $rate-font-size;
    color: var(--primary-color);
}

.ant-rate-star-first,
.ant-rate-star-second {
    color: $gray-lighter;
}
