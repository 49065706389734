.ant-notification {
    font-family: $font-family-base;
    font-size: $font-size-base;
    line-height: $line-height-base;
    color: $body-color;
    margin: 0 24px 0 0;
    padding: 0;
    list-style: none;
    position: fixed;
    z-index: $zindex-popover;
    width: $notification-width;
    max-width: calc(100vw - 32px);
}

.ant-notification-notice-message {
    font-size: 1.286rem;
    color: var(--primary-text-color);
    font-weight: $font-weight-semibold;
}

.ant-notification-notice-with-icon {
    .ant-notification-notice-message {
        font-size: 1.286rem;
    }
}

.ant-notification-notice-description {
    color: var(--text-color);
}

.anticon {
    &.ant-notification-notice-icon-success {
        color: $state-success-text;
    }

    &.ant-notification-notice-icon-error {
        color: $state-danger-text;
    }

    &.ant-notification-notice-icon-warning {
        color: $state-warning-text;
    }

    &.ant-notification-notice-icon-info {
        color: $brand-info;
    }
}

.ant-notification-notice {
    background-color: var(--section-background-color);
}

.ant-notification-notice-icon {
    line-height: 0;
}
